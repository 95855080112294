


















import { Component, Vue } from 'vue-property-decorator';
import SupportTicketTable from '../../components/DashboardSupportTable.vue';
import SupportCreateTicketForm from '../../components/DahsboardCreateTicketForm.vue';

@Component({
  components: {
    SupportTicketTable,
    SupportCreateTicketForm
  },
})
export default class DashboardSupport extends Vue {
    private search = '';
    private create_form_visible = false;
}
