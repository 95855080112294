





















import {
    User
} from '@/classes/TayehApi';
import {
  createSupportTicket,
  getSupportTicketCategories,
    GetUserSupportTicketCategoryResponse
} from '@/classes/TayehApi/Support';
import {
    Component,
    Vue
} from 'vue-property-decorator'
@Component
export default class DashboardCreateTicketForm extends Vue {
    private title: string = null;
    private category_id: number = null;
    private message: string = null;
    private categories: GetUserSupportTicketCategoryResponse[] = null;

    async createTicket() {
        try {
            createSupportTicket({
                title: this.title,
                category_id: this.category_id,
                message: this.message
            });
            alert('تیکت با موفقیت ایجاد شد.')
        } catch (err) {
            alert('لطفا دوباره تلاش کنید.')
        }
    }


    public get canSubmit(): boolean {
        return typeof this.title === 'string' && this.title.length > 3 &&
            this.category_id &&
            typeof this.message === 'string' && this.message.length > 9
    }


    async created() {
        const res = await getSupportTicketCategories();
        this.categories = res.data;
    }
}
