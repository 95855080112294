




































	// import {encode} from '@/classes/TayehApi'
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { deleteTicket, getTickets } from '@/classes/TayehApi/Support';
    @Component({
    })
	export default class DashboardSupportTable extends Vue {
		@Prop() search: string;
		private tickets: any[] = [];
		
		private count = 0;
		private loading = false;
		private page = 1;
		
		@Watch('search')
        async getTickets() {
            this.loading = true;
            const res = await getTickets(this.page-1, this.search)
			this.tickets = res.data.tickets;
			this.count = res.data.count;
			this.loading = false;
		}

		async askForDelete(ticket_id: number) {
			try {
				// await this.$confirm('آیا میخواهید این تیکت را حذف کنید؟', {
				// 	title: 'حذف مشتری',
				// 	confirmButtonText: 'بله',
				// 	cancelButtonText: 'خیر'
				// })
				this._delete(ticket_id);
			} catch (err) {
				return;
			}
        }
		async _delete(ticket_id: number) {
			this.loading = true;
			try {
				await deleteTicket(ticket_id)
			} catch (err) {
				alert(err)
			} finally {
				this.getTickets();
			}
			this.loading = false;
        }
        
        handleRowClick (row: any){
            this.$router.push({name: 'support-ticket', params: {ticket_id: row.data.id}})
        }
        

		mounted() {
			this.getTickets();
		}
	}
